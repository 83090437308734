<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 w-auto">
        Back Pay
      </h1>
      <div class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" v-if="loadingData">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="px-3">
        <card class="p-5 mt-6">
          <div class="border rounded border-romanSilver border-dashed p-3">
            <p class="font-bold mb-8">Pay Run Information</p>
            <div class="flex w-full gap-3">
              <card class="p-3 w-52">
                <h6 class="uppercase mb-2 font-bold text-sm text-flame">
                  Pay run Type
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.payType }}
                </h6>
              </card>
              <card class="p-3 ml-2">
                <h6 class="uppercase font-bold text-sm text-flame">
                  Pay Cycle Details
                </h6>
                <div class="flex">
                  <div class="flex">
                    <h6 class="font-semibold mt-2 text-sm text-jet uppercase">
                      Pay Cycle:
                    </h6>
                    <span
                      class="px-2 py-1 ml-2 mt-1 mr-4 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                     "
                    >
                      {{
                        `${$DATEFORMAT(
                          new Date(`${payrunData.month}-01-${payrunData.year}`),
                          "MMMM, yyyy"
                        )}`
                      }}
                    </span>
                  </div>

                  <div class="flex">
                    <h6 class="font-semibold mt-2 text-sm text-jet uppercase">
                      Payment frequency:
                    </h6>
                    <span
                      class="px-2 py-1 ml-2 mt-1 mr-4 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                      "
                    >
                      {{
                        payrunData.payFrequency === "twice_monthly"
                          ? "Twice Monthly"
                          : payrunData.payFrequency === "weekly"
                          ? "Four Times Monthly"
                          : payrunData.payFrequency
                      }}
                    </span>
                  </div>
                  <div class="flex">
                    <h6 class="font-semibold text-sm mt-2 text-jet uppercase">
                      Payment Schedule:
                    </h6>
                    <span
                      class="px-2 py-1 ml-2 mt-1 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                      "
                    >
                      {{
                        `${$getOrdinal(payrunData.paySplitPosition)} Payment`
                      }}
                    </span>
                  </div>
                </div>
              </card>
              <card class="w-auto p-2">
                <p class="uppercase text-flame mt-2 font-extrabold text-sm">
                  Created at
                </p>
                <p class="text-sm uppercase">
                  {{
                    `${$DATEFORMAT(
                      new Date(payrunData.createdAt),
                      "MMMM dd, yyyy"
                    )}`
                  }}
                </p>
              </card>
            </div>
          </div>

          <div class="flex justify-between mt-5">
            <div class="flex gap-5 flex-1">
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  Due Date
                </p>
                <p class="text-lg text-darkPurple font-bold uppercase">
                  {{
                    `${$DATEFORMAT(
                      new Date(payrunData.payDate),
                      "MMMM dd, yyyy"
                    )}`
                  }}
                </p>
              </card>
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  No. of Employees
                </p>
                <p class="text-lg text-darkPurple font-bold uppercase">
                  {{ totalEmployees }}
                </p>
              </card>
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  Total Pay
                </p>
                <p class="text-lg text-darkPurple font-bold uppercase">
                  {{ convertToCurrency(totalNetPay) }}
                </p>
              </card>
            </div>
            <div class="flex items-center ml-4">
              <Button
                background-color="none"
                class="text-white mr-5 bg-dynamicBackBtn rounded"
                border="1px solid #F15A29"
                @click="submitBackPay('review')"
                :disabled="checkboxes.length === 0"
              >
                Submit Payrun
              </Button>
              <Button
                class="bg-white mr-3 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                style-name="width:inherit"
                :disabled="checkboxes.length === 0"
                @click="submitBackPay('draft')"
              >
                Submit as Draft
              </Button>
            </div>
          </div>
          <div class="border w-full bg-romanSilver mt-8" />
        </card>
        <card class="mt-5">
          <sTable
            :headers="headers"
            :items="tableData"
            style="width:100%; height:auto"
            class="w-full"
            aria-label="table"
            has-number
            :loading="false"
            :has-checkbox="true"
            @rowSelected="processEmployees($event)"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.photo" class="flex items-center gap-3">
                <img
                  class=""
                  :src="item.data.photo"
                  alt="photo"
                  v-if="item.data.photo"
                  style="height: 30px; width: 30px; border-radius: 5px"
                />
                <div
                  style="height: 35px; width: 35px; border-radius: 5px"
                  class="text-blueCrayola border text-center font-semibold pt-2"
                  v-else
                >
                  {{ $getInitials(`${item.data.fname}`) }}
                </div>
              </div>

              <div
                class="tw-font-bold flex flex-col"
                style="color: #525252"
                v-else-if="item.fname"
              >
                {{ item.data.fname }} {{ item.data.lname }}
              </div>

              <span v-else-if="item.regularEarnings" class="tw-text-center">
                {{
                  !item.data.regularEarnings
                    ? "Nil"
                    : convertToCurrency(item.data.regularEarnings)
                }}
              </span>
              <span
                v-else-if="item.additions"
                class="flex flex-col justify-center"
              >
                <span class="flex">
                  <Menu
                    right
                    top="-300"
                    margin="46"
                    class="p-0"
                    v-if="item.data.additions"
                  >
                    <template v-slot:title>
                      <div class="underline text-blueCrayola">
                        {{ convertToCurrency(item.data.additions) }}
                      </div>
                    </template>
                    <div style="width: 250px; padding: 10px 5px">
                      <scroll-container height="200px">
                        <div
                          class="flex flex-col py-2 px-3"
                          v-for="(additions, index) in item.data
                            .monthlyAdditionsPayItems"
                          :key="index"
                        >
                          <p class="pt-1 flex flex-grow text-sm font-semibold">
                            {{ additions.name }}
                          </p>
                          <p class="pt-1 flex text-xs text-romanSilver">
                            {{ convertToCurrency(additions.amount) }}
                          </p>
                        </div>
                      </scroll-container>
                    </div>
                  </Menu>
                  <span v-else>
                    {{ convertToCurrency(0) }}
                  </span>
                  <span class="">
                    <Icon
                      class-name="text-flame cursor-pointer ml-4 self-center"
                      size="xms"
                      @click.native="handleClick('addition', item.data.userId)"
                      icon-name="addition"
                    />
                  </span>
                  <span v-if="item.data.additions > 1">
                    <Icon
                      class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                      size="xms"
                      @click.native="handleEdit('addition', item.data.userId)"
                      icon-name="edit"
                    />
                  </span>
                </span>
              </span>
              <span
                v-else-if="item.deductions"
                class="flex flex-col justify-center"
              >
                <span class="flex">
                  <Menu
                    right
                    top="-300"
                    margin="54"
                    class="p-0"
                    v-if="item.data.deductions"
                  >
                    <template v-slot:title>
                      <div class="underline text-desire">
                        ({{ convertToCurrency(item.data.deductions) }})
                      </div>
                    </template>
                    <div style="width: 250px; padding: 10px 5px">
                      <scroll-container height="200px">
                        <div
                          class="flex flex-col py-2 px-3"
                          v-for="(deductions, index) in item.data
                            .monthlyDeductionsPayItems"
                          :key="index"
                        >
                          <p class="pt-1 flex flex-grow text-sm font-semibold">
                            {{ deductions.name }}
                          </p>
                          <p class="pt-1 flex text-xs text-romanSilver">
                            {{ convertToCurrency(deductions.amount) }}
                          </p>
                        </div>
                      </scroll-container>
                    </div>
                  </Menu>
                  <span v-else>
                    {{ convertToCurrency(0) }}
                  </span>
                  <span>
                    <Icon
                      class-name="text-flame cursor-pointer ml-4 self-center"
                      size="xms"
                      @click.native="handleClick('deduction', item.data.userId)"
                      icon-name="addition"
                    />
                  </span>
                  <span v-if="item.data.deductions > 1">
                    <Icon
                      class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                      size="xms"
                      @click.native="handleEdit('deduction', item.data.userId)"
                      icon-name="edit"
                    />
                  </span>
                </span>
              </span>
              <span v-else-if="item.paye" class="tw-text-center">
                {{ item.data.paye ? convertToCurrency(item.data.paye) : 0 }}
              </span>
              <span v-else-if="item.totalPensionAmount" class="tw-text-center">
                {{
                  item.data.totalPensionAmount
                    ? convertToCurrency(item.data.totalPensionAmount)
                    : 0
                }}
              </span>
              <span v-else-if="item.nhfAmount" class="tw-text-center">
                {{
                  item.data.nhfAmount
                    ? convertToCurrency(item.data.nhfAmount)
                    : 0
                }}
              </span>
              <span v-else-if="item.netPay" class="grosspay">
                {{ convertToCurrency(item.data.netPay) }}
              </span>
            </template>
          </sTable>
        </card>
      </div>
    </div>

    <side-modal
      :open-modal="openModal"
      :modal-type="modalType"
      :user-id="userId"
      @close="handleClose"
    />

    <edit-side-modal
      :open-modal="openEditModal"
      :modal-type="modalType"
      :user-id="userId"
      @close="handleEditClose"
    />
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapState } from "vuex";

import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Card from "@/components/Card";
import Button from "@/components/Button";
import ScrollContainer from "@/components/ScrollContainer";
import Menu from "@/components/Menu";

export default {
  components: {
    BackButton,
    Card,
    ScrollContainer,
    Button,
    Breadcrumb,
    Menu,
    STable,
    SideModal: () => import("../SideModal"),
    EditSideModal: () => import("../EditSideModal"),
  },
  data() {
    return {
      openModal: false,
      openEditModal: false,
      openTransactionModal: false,
      loadingData: true,
      confirmModal: false,
      delModal: false,
      showOfflineModal: false,
      showOfflineSidebar: false,
      showCancelModal: false,
      showPasswordModal: false,
      modalType: "",
      userId: "",
      password: "",
      checkboxes: [],
      disableBtn: false,
      decryptPassword: false,
      offline: {
        paidAt: "",
        paymentSource: "",
        paymentTransactionId: "",
      },
      payrunId: "",
      year: "",
      netPaySum: 0,
      paymentType: "",
      getToday: "",
      seeComments: false,
      payCycleYear: "",
      payrunData: {},
      payrunType: "",
      totalEmployees: 0,
      totalNetPay: 0,
      payment: {},
      tableData: [],
      approvalsData: {},
      ActionType: "Approve pay run",
      approverDetails: {},
      requestProgress: [],
      approversNumber: "0",
      selectedEmployees: [],
      payCycleMonth: "",
      comment: "",
      response: "",
      responseBg: "",
      success: false,
      breadcrumbs: [
        { disabled: false, text: "Payroll", href: "Payroll", id: "Payroll" },
        { disabled: false, text: "Payrun", href: "Payrun", id: "Payrun" },
        {
          disabled: false,
          text: "Off-Cycle Pay",
          href: "Payrun",
          id: "Off-Cycle",
        },
        { disabled: false, text: "Back Pay", href: "Payrun", id: "BackPay" },
      ],
      categoryArray: [
        {
          name: "Approve pay run",
          value: "Approve pay run",
          radioName: "job",
        },
        {
          name: "Reject pay run",
          value: "Reject pay run",
          radioName: "job",
        },
        {
          name: "Return for review",
          value: "Return for review",
          radioName: "job",
        },
      ],
      payload: {
        employeeIds: [],
      },
      headers: [
        { title: "", value: "photo", width: 5 },
        { title: "Employee Name", value: "fname", width: 15 },
        { title: "Regular Earnings", value: "regularEarnings", width: 12 },
        { title: "Additions", value: "additions", width: 12 },
        { title: "Deductions", value: "deductions", width: 12 },
        { title: "PAYE", value: "paye", width: 12 },
        { title: "Pension", value: "totalPensionAmount", width: 12 },
        { title: "NHF", value: "nhfAmount", width: 8 },
        { title: "Net Pay", value: "netPay", width: 12 },
      ],
    };
  },
  computed: {
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
    ...mapState({
      banks: (state) =>
        state.Banks.map((bank) => ({
          ...bank,
          id: bank.code,
        })),
    }),
  },
  methods: {
    handleClose() {
      this.getOnePayrun("?page=1&perPage=50");
      this.openModal = false;
      this.modalType = "";
    },

    handleEditClose() {
      this.getOnePayrun("?page=1&perPage=50");
      this.openEditModal = false;
      this.modalType = "";
    },
    handleEdit(type, id) {
      this.modalType = type;
      this.userId = id;
      this.openEditModal = true;
    },

    processEmployees(value) {
      this.payload.createdBy = this.$AuthUser.id;
      this.checkboxes = value;
      this.payload.month = this.$route.query.month;
      this.payload.orgId = this.$orgId;
      this.payload.payFrequency = this.payrunData.payFrequency;
      this.payload.payType = this.payrunData.payType;
      this.payload.paymentType = this.payrunData.payType;
      this.payload.splitPosition = this.payrunData.paySplitPosition;
      this.payload.year = this.$route.query.year;
    },

    handleClick(type, id) {
      this.modalType = type;
      this.userId = id;
      this.openModal = true;
    },

    getCurrencyValue(arrData) {
      let amount = 0;

      arrData.forEach((data) => {
        amount += data.amount;
      });

      return this.convertToCurrency(amount);
    },

    submitBackPay(status) {
        const getPayrun = {
          payrun: {},
          employees: [],
          type: 'backpay'
        };
        getPayrun.payrun.id = this.$route.params.id;
        getPayrun.payrun.payrollStatus = status;

        // eslint-disable-next-line array-callback-return
      this.checkboxes.forEach((data) => {
        getPayrun.employees.push(data.userId);
      });

      this.$_editOnePayrun(getPayrun)
        .then((result) => {
          this.disableBtn = false;
          this.$toasted.success("Pay run created successfully", {
            duration: 5000,
          });
            this.$router.push({name : "BackPay", query: { currentTab: status === 'draft' ? 'Drafts' : 'Under Review' }});
          return result;
        })
        .catch((err) => {
          this.disableBtn = false;
          this.$toasted.error(
            "An error occured, Please contact an IT personnel",
            { duration: 5000 }
          );
          return err;
        });
    },

    getOnePayrun(params) {
      this.totalNetPay = 0;
      this.$_getOnePayrun(this.$route.params.id, params).then((result) => {
        const { employees, ...payrun } = result.data.payrun;
        this.payment = result.data.payment;
        this.payrunData = payrun;
        this.metaData = result.data.payrun.meta;

        this.tableData = employees.map((emp) => ({
          ...emp,
          paye: emp.monthlyPAYE,
          status: payrun.payrollStatus,
        }));

        if (employees) {
          employees.forEach((val) => {
            this.netPaySum += val.netPay;
          });
        }
        employees.forEach((item) => {
          this.totalNetPay += item.netPay;
        });
        this.totalEmployees = employees.length;
        this.payCycleMonth = result.data.payrun.month;
        this.payCycleYear = result.data.payrun.year;
      });
      this.loadingData = false;
      this.tableLoading = false;
    },
  },

  mounted() {
    // this.getBackPayPayRun();
    this.getOnePayrun("?page=1&perPage=50");
  },
};
</script>

<style scoped>
.bgCarrot {
  background-color: rgba(233, 147, 35, 0.08);
}
.widthInherited {
  width: inherit;
}
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.grosspay {
  background: #2176ff26;
  padding: 0.3rem 0.7rem;
  color: #2176ff;
  border-radius: 5px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
